import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import JobsPage from "../../Pages/JobsPage";
import SignupPage from "../../Pages/SignupPage";
import Navigation from "../Navigation";
import LoginPage from "../../Pages/LoginPage";
import { AuthContext } from "../../Providers/UserProvider";

function Router() {
  const { user, initialized } = useContext(AuthContext);

  return (
    <div>
      {initialized ? (
        <BrowserRouter>
          {user ? (
            <>
              <Navigation />
              <Switch>
                <Route path="/app/jobs" component={JobsPage} />
                <Redirect to={{ pathname: "/app/jobs" }} />
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path="/login" component={LoginPage} />
              <Route path="/signup/:regionId/:shopId" component={SignupPage} />
              <Redirect to={{ pathname: "/login" }} />
            </Switch>
          )}
        </BrowserRouter>
      ) : (
        <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
          <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            <i className="fas fa-circle-notch fa-spin fa-5x"></i>
          </span>
        </div>
      )}
    </div>
  );
}
export default Router;
