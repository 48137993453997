import "./App.css";
import Router from "./Shared/Router";
import { UserProvider } from "./Providers/UserProvider";

function App() {
  return (
    <>
      <UserProvider>
        <Router />
      </UserProvider>
    </>
  );
}

export default App;
